export default (defaultStep) => ({
    step: defaultStep,
    language: '',
    model: '',

    goToStep (e) {
        this.step = e;
    },
    previousStep() {
        if(this.step === '') return;
        
        if(this.step === 'step2'){
            this.language = '';
            this.step = 'step1';
        }
        if(this.step === 'step3') {
            this.model = '';
            this.step = 'step2';
        }
    },
    isActive(step) {
        return step === this.step;
    },
    selectLang(lang) {
        this.language = lang;
        this.step = 'step2';
    },
    isSelectedLang(lang) {
        return lang === this.language;
    },
    selectModel(model) {
        this.model = model;
        this.step = 'step3';
    },
    restart() {
        this.step = defaultStep
        this.language = '';
        this.model = '';
    }
})